import React from "react"

import { withCustomerAddresses } from "./withCustomerAddresses"
import { CustomerAddressesForm } from "./Form/CustomerAddressesForm"
import { CustomerAddressesList } from "./List/CustomerAddressesList"

export const CustomerAddresses = withCustomerAddresses(
  ({
    addresses,
    address,
    savedAddress,
    errors,
    fieldRef,
    handleAdd,
    handleCancel,
    handleChange,
    handleDelete,
    handleEdit,
    handleSubmit,
    locales,
    loading,
    saving,
  }) => (
    <>
      <CustomerAddressesList
        addresses={addresses}
        address={address}
        handleAdd={handleAdd}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        locales={locales}
      />
      {address?.action && (
        <CustomerAddressesForm
          addresses={addresses}
          address={address}
          savedAddress={savedAddress}
          errors={errors}
          fieldRef={fieldRef}
          handleAdd={handleAdd}
          handleCancel={handleCancel}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          locales={locales}
          loading={loading}
          saving={saving}
        />
      )}
    </>
  )
)
