import React from "react"

import { withCustomerAddressesFormField } from "./withCustomerAddressesFormField"
import { Dropdown } from "../../../Styled/Dropdown/Dropdown"
import { FormLabel, FormDropdown, FormInput, FormFluidField } from "../../../Styled/Form"

interface Props {
  address: any
  field: any
  fieldRef: any
  handleChange: Function
  regions: any
}

export const CustomerAddressesFormField = withCustomerAddressesFormField(({ address, field, fieldRef, handleChange, regions }: Props) => (
  <FormFluidField width={field.width} key={field.name}>
    <FormLabel>{`${field.label}${field.required ? `*` : ``}`}</FormLabel>

    {field.type === `select` ? (
      <FormDropdown disabled={!address[`country`] && field.name === `province`}>
        <Dropdown
          label={!address[field.name] ? field.placeholder : null}
          name={field.name}
          onChange={(value, name) => handleChange({ target: { name, value } })}
          options={regions[field.name]}
          required={field.required}
          value={address[field.name]}
        />
      </FormDropdown>
    ) : (
      <FormInput
        ref={fieldRef || null}
        name={field.name}
        value={address[field.name] || ""}
        onChange={handleChange}
        type={`text`}
        placeholder={field.placeholder}
        readOnly={field.readOnly}
        required={field.required}
      />
    )}
  </FormFluidField>
))
