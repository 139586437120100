import React from "react"
import { CountryRegionData } from "react-country-region-selector"

export const withCustomerAddressesForm = Component => ({ name = `CustomerAddressesForm`, address, savedAddress, locales, ...props }) => {
  const regions = {
    country: CountryRegionData.map(country => ({ label: country[0], value: country[0] })),
    province: address?.country
      ? CountryRegionData.find(country => country[0] === address?.country)[2]
          .split(`|`)
          .map(state => ({ label: state.split(`~`)[0], value: state.split(`~`)[0] }))
      : [],
  }

  const fields = [
    {
      name: "firstName",
      label: locales?.firstName,
      required: true,
      width: "1/2",
    },
    {
      name: "lastName",
      label: locales?.lastName,
      required: true,
      width: "1/2",
    },
    {
      name: "address1",
      label: locales?.addressesLine1,
      required: true,
    },
    {
      name: "address2",
      hidden: true,
      label: locales?.addressesLine2,
    },
    {
      name: "country",
      label: locales?.addressesCountry,
      placeholder: "Select",
      required: true,
      type: "select",
      width: "1/2",
    },
    {
      name: "province",
      label: locales?.addressesProvince,
      placeholder: "Select",
      required: true,
      type: "select",
      width: "1/2",
    },
    {
      name: "city",
      label: locales?.addressesCity,
      required: true,
      width: "1/2",
    },
    {
      name: "zip",
      label: locales?.addressesZip,
      required: true,
      width: "1/2",
    },
    {
      name: "company",
      hidden: true,
      label: locales?.addressesCompany,
    },
    {
      name: "phone",
      label: locales?.addressesPhone,
      required: true,
    },
    {
      name: "default",
      label: locales?.addressesPrimary,
      hidden: true,
    },
  ]

  const changed = address?.action === `create` || fields.filter(field => address?.[field?.name] !== savedAddress?.[field?.name])?.length > 0
  const invalid = fields.filter(field => field?.required && !address?.[field?.name])?.length > 0

  Component.displayName = name
  return (
    <Component
      {...props}
      address={address}
      changed={changed}
      fields={fields?.filter(({ hidden }) => !hidden)}
      invalid={invalid}
      locales={locales}
      regions={regions}
    />
  )
}
