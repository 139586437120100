import React from "react"

import { withCustomerAddressesList } from "./withCustomerAddressesList"
import { Addresses, Address, Content, Details, StyledP, StyledLabel, Links, StyledTextButton } from "./CustomerAddressesListStyles"

interface Props {
  addresses: Array<any>
  address: any
  handleAdd: Function
  handleDelete: Function
  handleEdit: Function
  locales: any
}

export const CustomerAddressesList = withCustomerAddressesList(
  ({ addresses, address: selectedAddress, handleAdd, handleDelete, handleEdit, locales }: Props) => (
    <Addresses>
      {addresses?.map(({ default: primary, id, address1, city, province, country, zip, firstName, lastName }) => (
        <Address key={id}>
          <Content selected={id === selectedAddress?.id}>
            <Details>
              <StyledLabel>{primary ? locales?.addressesPrimaryLabel : ``}&nbsp;</StyledLabel>
              <StyledP as={`div`}>
                {(firstName || lastName) && (
                  <p>
                    {firstName} {lastName}
                  </p>
                )}
                {address1 && <p>{address1},</p>}
                {(city || province || zip) && (
                  <p>
                    {city} {province} {zip},
                  </p>
                )}
                {country && <p>{country},</p>}
              </StyledP>
            </Details>

            <Links>
              <StyledTextButton onClick={() => handleEdit(id)}>{locales?.edit}</StyledTextButton>
              <StyledTextButton onClick={() => handleDelete(id)}>{locales?.delete}</StyledTextButton>
            </Links>
          </Content>
        </Address>
      ))}

      <Address>
        <Content selected={selectedAddress?.action === `create`}>
          <Details>
            <StyledP>{locales?.addressesNew}</StyledP>
          </Details>

          <Links>
            <StyledTextButton onClick={handleAdd}>{locales?.create}</StyledTextButton>
          </Links>
        </Content>
      </Address>
    </Addresses>
  )
)
