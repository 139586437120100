import React, { useRef } from "react"

import { useCustomerAddresses } from "@hooks/useCustomer"
import { useLanguage } from "@hooks/useLanguage"
import { Account } from "../../Account/Account"

export const withCustomerAddresses = Component => ({ name = `CustomerAddresses` }) => {
  const {
    addresses,
    address,
    setAddress,
    savedAddress,
    setSavedAddress,
    createAddress,
    updateAddress,
    deleteAddress,
    initialData,
    loading,
    saving,
    errors,
  } = useCustomerAddresses()
  const locales = { ...useLanguage(`account`), ...useLanguage(`form`) }
  const fieldRef = useRef()

  const handleAdd = () => {
    setAddress({ ...initialData, action: "create" })
    setTimeout(() => {
      fieldRef?.current?.select()
    }, 100)
  }

  const handleCancel = () => {
    setAddress({ ...initialData })
    setSavedAddress(null)
  }

  const handleChange = ({ name, target, type }) => {
    setAddress(prevState => ({
      ...prevState,
      [target?.name || name]: type === "checkbox" ? !address[name] : target?.value,
    }))
  }

  const handleDelete = async id => await deleteAddress(id)

  const handleEdit = id => {
    setAddress({
      action: "update",
      ...addresses?.filter(address => address?.id === id)[0],
    })
    setSavedAddress({ ...addresses?.filter(address => address?.id === id)[0] })
    setTimeout(() => {
      fieldRef?.current?.select()
    }, 100)
  }

  const handleSubmit = async event => {
    event.preventDefault()

    if (address?.action === "update") await updateAddress(address?.id, address)
    if (address.action === "create") await createAddress(address)
  }

  Component.displayName = name
  return (
    <Account description={locales.addressesDescription} title={locales.addressesTitle}>
      <Component
        addresses={addresses}
        address={address}
        savedAddress={savedAddress}
        errors={errors}
        fieldRef={fieldRef}
        handleAdd={handleAdd}
        handleCancel={handleCancel}
        handleChange={handleChange}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        handleSubmit={handleSubmit}
        locales={locales}
        loading={loading}
        saving={saving}
      />
    </Account>
  )
}
