import React from "react"

import { withCustomerAddressesForm } from "./withCustomerAddressesForm"
import { Checkbox } from "../../../Styled/Checkbox/Checkbox"
import {
  Form,
  FormSubheading,
  FormFields,
  FormButton,
  FormLinkButton,
  FormLinkButtonSpacing,
  FormButtonWrapper,
  FormError,
} from "../../../Styled/Form"
import { CustomerAddressesFormField } from "../Field/CustomerAddressesFormField"

interface Props {
  address: any
  changed: boolean
  errors: any
  fields: any
  fieldRef: any
  handleCancel: Function
  handleChange: Function
  handleSubmit: Function
  invalid: boolean
  loading: boolean
  locales: any
  regions: any
  saving: boolean
}

export const CustomerAddressesForm = withCustomerAddressesForm(
  ({ address, changed, errors, fields, fieldRef, handleCancel, handleChange, handleSubmit, invalid, loading, locales, regions, saving }: Props) => (
    <Form onSubmit={handleSubmit} margin={`true`}>
      <FormSubheading>{address?.action === `update` ? locales?.addressesEdit : locales?.addressesAdd}</FormSubheading>

      <FormFields>
        {fields.map((field, i) => (
          <CustomerAddressesFormField
            key={`${address?.id || address?.action}_${field.name}`}
            address={address}
            field={field}
            fieldRef={!i && fieldRef}
            handleChange={handleChange}
            regions={regions}
          />
        ))}
      </FormFields>

      <Checkbox name={`default`} onChange={handleChange} checked={address?.default || false} externalState>
        {locales?.addressesPrimary}
      </Checkbox>

      <FormButtonWrapper>
        <FormButton disabled={saving || loading || !changed || invalid} type={`submit`}>
          {address?.action === `update` ? locales?.save : locales?.addressesSave}
        </FormButton>
        <FormLinkButtonSpacing>
          <FormLinkButton center disabled={saving || loading} onClick={handleCancel} type={`button`}>
            {locales?.cancel}
          </FormLinkButton>
        </FormLinkButtonSpacing>
      </FormButtonWrapper>

      {errors?.length > 0 && errors?.map(({ code, message }) => <FormError key={code}>{message}</FormError>)}
    </Form>
  )
)
